<template>
  <div class="px-2 mt-1">
    <header-slot>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'companies-old-management-us' }"
        exact
        exact-active-class="active"
        :link-classes="[
          this.$route.meta.pageTitle == 'USA' ? 'active' : '',
          'px-3',
          bgTabsNavs,
        ]"
      >
        USA
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'companies-old-management-pe' }"
        exact
        exact-active-class="active"
        :link-classes="[
          this.$route.meta.pageTitle == 'PERU' ? 'active' : '',
          'px-3',
          bgTabsNavs,
        ]"
      >
        PERU
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
      id="tab-settings"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
  
  <script>
export default {
  methods: {
    isMangementDynamicForms() {
      let element = document.getElementById("tab-settings");
      if (this.$route.name === "management-settings-dynamic-forms") {
        element.classList.remove("card");
      } else {
        element.classList.add("card");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.isMangementDynamicForms();
    },
  },
  mounted() {
    this.isMangementDynamicForms();
  },
};
</script>